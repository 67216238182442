import React from "react";

import InterwinsWhite from "../images/interwins-white.png";
import MksWhite from "../images/mks-white.png";
import MotorolaWhite from "../images/motorola-white.png";

function Footer() {
  return (
    <div className="relative bottom-0 w-full pb-2 mt-10 bg-black">
      <div className="flex items-center justify-between px-3 py-5 lg:px-10">
        <img
          src={InterwinsWhite}
          alt="Interwins logo"
          className="h-6 md:h-8 lg:h-14"
        />
        <img
          src={MksWhite}
          alt="Interwins logo"
          className="w-16 md:w-28 lg:w-44"
        />
        <img
          src={MotorolaWhite}
          alt="Interwins logo"
          className="h-4 md:h-8 lg:h-11"
        />
      </div>
      <p className="text-white text-[0.2rem] px-5 text-center md:text-[0.5rem]">
        MOTOROLA, MOTO, MOTOROLA SOLUTIONS y el logotipo de la M estilizada son
        marcas comerciales o marcas comerciales registradas de Motorola
        Trademark Holdings, LLC y son utilizadas bajo licencia. Todas las demás
        marcas pertenecen a sus respectivos propietarios. © 2021 Motorola
        Solutions, Inc. Todos los derechos reservados
      </p>
    </div>
  );
}

export default Footer;
