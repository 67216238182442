import React from 'react';

import clsx from 'clsx';
import { useParams } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';
import ThanksR7Desktop from '../images/thanks-r7-desktop.png';
import ThanksR2Desktop from '../images/thanks-r2-desktop.png';

function ThanksPerRadioPage() {
  const { radioModel } = useParams();
  const isR7 = radioModel === 'r7';

  return (
    <div className={clsx('min-h-screen max-h-screen !text-black')}>
      <Header showImage={false} showSelector noSelected />
      <h2
        className={clsx(
          'text-2xl mt-10 mb-3 font-bold text-center text-[#005EB8] lg:text-[2.5rem] 2xl:mt-8 2xl:mb-4 2xl:text-4xl',
        )}
      >
        ¡GRACIAS POR TU SOLICITUD!
      </h2>
      <div className='flex flex-col gap-y-2 px-5'>
        <p className='text-sm text-center md:text-base md:px-10 lg:px-44 2xl:px-80'>
          Hemos recibido tu solicitud y nuestro equipo de atención está
          revisando los detalles. Nos pondremos en contacto contigo a la mayor
          brevedad posible.
        </p>
        <p className='text-sm text-center md:text-base md:px-10 lg:px-44 2xl:px-80'>
          Te informamos que el tiempo de respuesta es de un máximo de 48 horas
          hábiles. Apreciamos tu paciencia y comprensión mientras trabajamos
          para brindarte la mejor atención y resolver tus inquietudes de manera
          eficaz.
        </p>
        <div className='flex justify-center items-center w-full px-5 my-10'>
          <img
            src={isR7 ? ThanksR7Desktop : ThanksR2Desktop}
            className='max-h-96'
            alt='successful'
          />
        </div>
      </div>
      <p
        className={clsx(
          'text-sm mx-5 text-center my-10 md:text-lg lg:px-44 2xl:px-0 2xl:mx-80 2xl:my-5',
        )}
      >
        Este mensaje está respaldado por el canal oficial de Motorola Solutions
        en Chile: MKS e InterWins.
      </p>
      <Footer />
    </div>
  );
}

export default ThanksPerRadioPage;
