import client from './index';

export async function sendEmail({ fullName, email, company, phone, message, isR7 }) {
  try {
    const res = await client.post('/walkie_talkies/send_radio_email', {
      full_name: fullName,
      company,
      phone,
      message,
      email,
      is_r7: isR7
    });
    console.log(res.data);
    return res.data;
  } catch (e) {
    console.log(e);
    return null;
  }
}
