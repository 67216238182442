import client from "./index";

export async function checkSeries(email, series, model) {
  try {
    const res = await client.post("/walkie_talkies", { email, series, model });
    return res.data;
  } catch (err) {
    return undefined;
  }
}

export async function getGuaranteeResult(series, model, email) {
  try {
    const res = await client.post("walkie_talkies", { series, model, email });
    return res.data;
  } catch (err) {
    return undefined;
  }
}

export async function healthcheck() {
  try {
    const res = await client.get("/");
    return res.data;
  } catch (err) {
    return undefined;
  }
}
