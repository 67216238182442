import React from 'react';

import AcquiredMobile from '../images/acquired-mobile.png';

function Success() {
  const text = 'contará con garantía de fábrica'.toUpperCase();

  return (
    <div className='px-5'>
      <p className='text-sm text-justify md:text-xl md:px-10 lg:px-44 2xl:px-80'>
        Este equipo <span className='font-bold'>SI FUE ADQUIRIDO</span> a través
        del canal oficial de Motorola en Chile, por tanto, si tu modelo
        adquirido está dentro de los tiempos de cobertura
      </p>
      <h3 className='text-center text-[#00488A] font-bold text-xl my-10 md:text-2xl lg:text-3xl'>
        {text}
      </h3>
      <div className='flex justify-center items-center w-full px-5 my-10'>
        <img src={AcquiredMobile} alt='successful' />
      </div>
    </div>
  );
}

export default Success;
