import React, { useEffect, createRef } from 'react';

import Header from '../components/Header';
import Explanation from '../components/Explanation';
import CheckGuaranteeForm from '../components/CheckGuaranteeForm';
import Footer from '../components/Footer';
import Definition from '../components/Definition';
import GuaranteeInChile from '../components/GuaranteeInChile';

import HeaderMobileImage from '../images/header-mobile-v2.jpg';
import HeaderDesktop from '../images/header-desktop-v2.jpg';

import { healthcheck } from '../api/check';

function IndexPage() {
  const formRef = createRef();

  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const fetchHealthcheck = async () => {
      await healthcheck();
    };

    fetchHealthcheck();
  });

  return (
    <div className='bg-black/10 min-h-screen !text-black'>
      <Header
        scrollToForm={scrollToForm}
        mobileImage={HeaderMobileImage}
        desktopImage={HeaderDesktop}
        showSelector
        isIndex
      />
      <Explanation />
      <CheckGuaranteeForm formRef={formRef} />
      <GuaranteeInChile />
      <Definition />
      <Footer />
    </div>
  );
}

export default IndexPage;
