import React from 'react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import IndexPage from './pages/IndexPage';
import Thanks from './components/thanks';
import ModelsPage from './pages/ModelsPage';
import ThanksPerRadioPage from './pages/ThanksPerRadioPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<IndexPage />} />
        <Route path='/mototrbo' element={<ModelsPage />} />
        <Route path='/:result' element={<Thanks />} />
        <Route path='/mototrbo/:radioModel' element={<ThanksPerRadioPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
