import React, { useState } from 'react';

import clsx from 'clsx';

import SealedRadios2 from '../images/radios-v2.png';

function Definition() {
  const [open, setOpen] = useState(false);
  const question1 = '¿Qué defectos cubre la garantía de fábrica Motorola?';
  const question2 = '¿Cuál es el tiempo de cobertura de la garantia Motorola?';
  const notCovered = '¿Qué defectos NO cubre la garantía Motorola?';

  return (
    <div>
      <p className='text-[#00488A] text-center mx-5 text-lg md:text-xl lg:font-bold mt-20 lg:mx-80 lg:mb-10'>
        <span className='font-bold text-xl'>
          A contar del 21 de Septiembre del 2022
        </span>
        , las radios Motorola con garantía de fábrica Motorola Solutions
        contarán con un sello de garantía.
      </p>
      <div className='flex flex-col items-center justify-center'>
        <img
          src={SealedRadios2}
          alt='Radios with guarentee seal'
          className='w-3/4 lg:w-1/2'
        />
        <div
          className='flex items-center justify-center bg-black w-10/12 mx-10 py-2 gap-x-2 mt-5 lg:w-1/2 hover:cursor-pointer'
          onClick={() => setOpen(!open)}
        >
          <h2
            className={clsx(
              'text-white font-bold text-center',
              open && 'rotate-90',
            )}
          >
            &gt;
          </h2>
          <h2 className='text-white text-center font-bold'>
            Información adicional
          </h2>
        </div>
        {open && (
          <div className='flex flex-col bg-black text-white w-10/12 mx-10 px-4 py-2 mt-3 pb-5 lg:flex-row lg:w-1/2 lg:py-5 lg:pb-10 lg:gap-x-8'>
            <div className='w-full lg:w-1/2 lg:ml-7'>
              <h4 className='font-bold lg:text-xl'>{question1}</h4>
              <p className='mt-4 leading-5'>
                La garantía de fábrica Motorola cubre los defectos eléctricos y
                mecánicos originados por el proceso de fabricación
              </p>
              <h4 className='font-bold mt-6 lg:text-xl'>{question2}</h4>
              <p className='my-6 font-bold'>Motorola Solutions | Cobertura</p>
              <ul className='list-disc pl-5 lg:pl-8'>
                <li>EP 350 | 24 meses.</li>
                <li>Serie MOTOTRBO | 36 meses(*).</li>
                <li>DEP 450 Análogo/Digital | 24 meses(**).</li>
                <li>DEP 550e/DEP570e | 24 meses(**).</li>
                <li>DEP 250 Análogo/Digital | 24 meses(**).</li>
                <li>DEM 300/400/500 | 24 meses.</li>
                <li>SL 500e | 24 meses(*).</li>
                <li>Accesorios | 12 meses.</li>
              </ul>
              <p className='mt-3'>
                (*) Expandible a 5 años contratando servicios Essential.
              </p>
              <p>
                (**) Expandible a 3 ó 5 años contratando servicios Essential.
              </p>
              <p className='font-bold my-3 lg:my-6'>
                Vertex Standard | Cobertura
              </p>
              <ul className='list-disc pl-5 lg:pl-8'>
                <li>Equipos | 12 meses</li>
                <li>Accesorios | 6 meses</li>
              </ul>
            </div>
            <div className='w-full lg:w-1/2 mt-5 lg:mt-0 lg:mr-5'>
              <h4 className='font-bold lg:text-xl'>{notCovered}</h4>
              <ul className='list-disc mt-3 ml-5 lg:ml-6 lg:pr-8'>
                <li>Mal uso, accidente, casos fortuitos o negligencia.</li>
                <li>
                  Pruebas, operación, mantenimiento, desmontaje, reparación,
                  instalación, alteración, modificación o ajustes inapropiados o
                  no autorizados por escrito por Motorola Solutions o no
                  realizados por un Centro de Servicios Autorizados de Motorola
                  Solutions en el país.
                </li>
                <li>
                  Uso de equipos, partes, o software de Motorola Solutions no
                  conforme el manual del producto
                </li>
                <li>
                  Derrames de comida o líquidos (con excepción de radios a
                  prueba de agua, siemrpe que se utilización sea conforme con lo
                  especificado en el manual).
                </li>
                <li>Uso de accesorios no originales.</li>
                <li>
                  Descargas eléctricas y las variaciones de la red eléctrica.
                </li>
                <li>
                  Fenómenos naturales, tales como tormentas eléctricas, lluvias,
                  inundaciones, incendios, etc.
                </li>
                <li>El robo o pérdida.</li>
                <li>
                  Placas de circuito impresa con daños físicos,
                  grietas/aplastada, destruida.
                </li>
                <li>
                  Rupturas o daños de antenas y otros accesorios como baterías,
                  micrófono o cargador, a menos que éstos sean por causa directa
                  de defectos de manufactura, material o mano de obra en la
                  fabricación de la radio.
                </li>
                <li>
                  Unidades a las cuales se les haya retirado el número de serie
                  de su etiqueta o que el mismo sea ilegible, o que haya sido
                  adulterado por software no autorizado por Motorola.
                </li>
                <li>
                  Mantenimiento preventivo o reprogramación de la configuración
                  del usuario.
                </li>
                <li>
                  Soporte 7x24 y gastos de envío del equipo a reparación
                  Motorola y su retorno al cliente.
                </li>
                <li>
                  Toda superficie plástica o pieza expuesta al exterior que haya
                  sido rayada, desgastada o dañada debido al uso normal por
                  parte del usuario.
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Definition;
