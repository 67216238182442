import React from 'react';

import GuaranteeSealMobile from '../images/guarantee-seal.png';
import TicketIcon from '../images/ticktet-icon.png';

const CHECKLIST = [
  'Radios Mototrbo originales',
  'Radios Mototrbo no intervenidas',
  'Radios Mototrbo con servicio técnico',
  'Radios Mototrbo con garantía Motorola en Chile',
];

function GuaranteeInChile() {
  return (
    <div className='px-6 md:mt-14 lg:mt-24'>
      <h2 className='text-center text-[1.3rem] font-bold my-8 md:text-4xl md:px-20'>
        ¿Qué significa la garantía Motorola en Chile?
      </h2>
      <div className='flex items-center justify-center w-full mb-10 lg:hidden'>
        <img
          src={GuaranteeSealMobile}
          alt='Guarantee seal'
          className='w-3/4 md:w-1/3'
        />
      </div>
      <div className='flex flex-col gap-y-5 md:px-14 lg:hidden'>
        <p className='md:text-2xl'>
          Motorola entrega garantía en Chile a equipos originales de modelos
          autorizados a ser comercializados en América Latina.
        </p>
        <p className='md:text-2xl'>
          El Sello de Garantía adherido al empaque, verifica que es un producto
          original.
        </p>
        <p className='md:text-2xl'>
          Esta Garantía se hace efectiva a través de los Servicios Técnicos
          Autorizados (SAM)
        </p>
        <p className='md:text-2xl'>
          Exija el Sello de Garantía Motorola a su vendedor y asegure su
          inversión.
        </p>
        <p className='text-lg font-bold md:text-2xl'>
          Recuerde: <br />
          Los equipos no distribuidos por Interwins y MKS en Chile, NO tienen
          Sello.
        </p>
        <div className='flex flex-col items-center justify-center mt-10 gap-y-5'>
          {CHECKLIST.map((check) => (
            <div
              className='flex items-center justify-start w-full ml-2 gap-x-5'
              key={check}
            >
              <img
                src={TicketIcon}
                alt='Ticket icon'
                className='w-8 h-8 md:h-10 md:w-10'
              />
              <p className='font-bold md:text-2xl'>{check}</p>
            </div>
          ))}
        </div>
      </div>
      <div className='items-center justify-center hidden lg:flex gap-x-10 mx-44 lg:mx-20'>
        <div className='flex flex-col flex-1 text-lg leading-5 gap-y-5'>
          <p className='text-base'>
            Motorola entrega garantía en Chile a equipos originales de modelos
            autorizados a ser comercializados en América Latina.
          </p>
          <p className='text-base'>
            El Sello de Garantía adherido al empaque, verifica que es un
            producto original.
          </p>
          <p className='text-base'>
            Esta Garantía se hace efectiva a través de los Servicios Técnicos
            Autorizados (SAM).
          </p>
        </div>
        <div className='flex items-center justify-center flex-5'>
          <img
            src={GuaranteeSealMobile}
            alt='Guarantee seal'
            className='w-11/12'
          />
        </div>
        <div className='flex flex-col flex-1 text-lg leading-5 gap-y-5'>
          <p className='text-lg font-bold'>
            Recuerde: <br />
            Los equipos no distribuidos por Interwins y MKS en Chile, NO tienen
            Sello.
          </p>
          <p className='text-base'>
            Exija el Sello de Garantía Motorola a su vendedor y asegure su
            inversión.
          </p>
        </div>
      </div>
      <div className='items-center justify-center hidden mt-10 lg:flex mx-44 lg:mx-24 gap-y-5'>
        {CHECKLIST.map((check) => (
          <div
            className='flex items-start justify-start ml-2 gap-x-5 h-full flex-1 h-44 xl:h-36'
            key={check}
          >
            <img
              src={TicketIcon}
              alt='Ticket icon'
              className='w-8 h-8 md:h-10 md:w-10'
            />
            <p className='font-bold md:text-xl'>{check}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default GuaranteeInChile;
