import React from 'react';
import { Link } from 'react-router-dom';

import clsx from 'clsx';

import LogoMotorolaWhite from '../images/logo-motorola-white.png';

function Header({
  desktopImage,
  mobileImage,
  showImage = true,
  scrollToForm,
  showSelector = false,
  isIndex = false,
  noSelected = false,
}) {
  return (
    <div>
      <div className='bg-black h-16 py-4 flex items-center justify-center'>
        <img src={LogoMotorolaWhite} alt='Motorola banner' className='h-full' />
      </div>
      {showSelector && (
        <div className='bg-[#F2F2F2] h-16 py-4 flex items-center justify-evenly text-black'>
          <Link
            to='/'
            className={clsx(isIndex && !noSelected && 'text-[#005EB8]')}
          >
            Inicio
          </Link>
          <Link
            to='/mototrbo'
            className={clsx(!isIndex && !noSelected && 'text-[#005EB8]')}
          >
            Equipos MOTOTRBO
          </Link>
        </div>
      )}
      {showImage && (
        <div onClick={scrollToForm}>
          <img src={mobileImage} alt='header mobile' className='lg:hidden' />
          <img src={desktopImage} alt='header' className='hidden lg:block' />
        </div>
      )}
    </div>
  );
}

export default Header;
