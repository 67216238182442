import React from 'react';

import GuaranteeSeal from '../images/seal-v2.png';

function Failure() {
  return (
    <div className='px-5 bg-[#BA0606]'>
      <div className='text-white flex flex-col items-center justify-center w-full space-y-5 md:px-32 lg:px-72 2xl:px-52'>
        <p className='md:text-xl text-center lg:text-lg lg:px-96'>
          Este equipo NO ESTÁ REGISTRADO en la base de datos de los
          Distribuidores Mayoristas Oficiales en Chile.
        </p>
        <p className='md:text-xl text-center lg:text-lg lg:px-96'>
          Consulta a tu vendedor para validar la garantía de tus equipos.
        </p>
      </div>
      <div className='mt-5 flex flex-col items-center justify-center w-full gap-y-5 2xl:gap-y-6'>
        <img
          src={GuaranteeSeal}
          alt='Guarantee seal'
          className='w-1/2 xl:w-1/5'
        />
      </div>
    </div>
  );
}

export default Failure;
