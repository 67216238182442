import React from "react";

import RadioStep from "./RadioStep";
import { RADIO_STEPS } from "../constants";

function Explanation() {
  return (
    <div className="lg:mx-48 mt-10 mb-20 lg:my-10">
      <div className="2xl:mx-48">
        <div className="md:pb-5 lg:pb-20">
          <h1 className="text-xl  font-bold text-center mb-10 md:mb-5 md:text-3xl md:mx-3 lg:mx-0 lg:mb-5 px-4 lg:px-0 2xl:px-20">
            Confirma la garantía de fábrica de tu equipo con el código Model y
            N° de serie
          </h1>
          <h3 className="text-center md:text-xl  px-4 lg:px-0 lg:-mb-1.5">
            Te explicamos el paso a paso de cómo confirmar si tu equipo tiene
            garantía.
          </h3>
          <h3 className="text-center md:text-xl">
            Debes seguir rigurosamente todos los pasos para generar una
            respuesta verídica.
          </h3>
        </div>
      </div>
      <div className="flex gap-x-5 gap-y-20 flex-col mt-10 md:mt-16 md:gap-y-24 lg:mt-0 lg:flex-row">
        {RADIO_STEPS.map((radioStep, id) => (
          <RadioStep
            key={id}
            title={radioStep.title}
            description={radioStep.description}
            image={radioStep.image}
            imageXl={radioStep.imageXl}
          />
        ))}
      </div>
    </div>
  );
}

export default Explanation;
