import React from 'react';

import clsx from 'clsx';

import GuardDesktop from '../images/guard-desktop.png';
import AltLogoDesktop from '../images/alt-logo-desktop.png';
import BuilderDesktop from '../images/builder-desktop.png';
import AltIconMobile from '../images/alt-icon-mobile.png';

const ITEMS = [
  {
    title: 'Radiocomunicación en los momentos que importan',
    subtitle:
      'Para mantener la seguridad de las personas mediante equipos de trabajos coordinados.',
    imageSrc: GuardDesktop,
  },
  {
    title: 'Radios estrictamente testeadas con PRUEBAS DE VIDA ACELERADA ALT',
    subtitle:
      'Que aseguran al menos 5 años de máximo rendimiento gracias a sus exigentes y rigurosas pruebas, que certifican la calidad de materiales, componentes y parámetros técnicos.',
    imageSrc: AltLogoDesktop,
    imageMobile: AltIconMobile,
  },
  {
    title: 'Radios Motorola para una transmitir mensajes críticos',
    subtitle:
      'En ambientes de trabajo con maquinarias ruidosas, en movimiento y con un ritmo acelerado.',
    imageSrc: BuilderDesktop,
  },
];

function RadioCard({ title, subtitle, imageSrc, imageMobile }) {
  return (
    <div
      className={clsx(
        'flex flex-col items-center flex-1 gap-y-10',
        'rounded-2xl text-center text-black bg-[#FAFAFA] shadow-xl',
        'px-14',
      )}
    >
      <div className='flex flex-col gap-y-1 mt-14'>
        <p className='text-xl'>{title}</p>
        <p className='text-sm'>{subtitle}</p>
      </div>
      <div>
        <img src={imageSrc || imageMobile} alt='radio' />
      </div>
    </div>
  );
}

function RadioMarketing() {
  return (
    <div className='flex flex-col gap-y-8 px-10 lg:flex-row lg:gap-x-8 py-5 lg:px-28'>
      {ITEMS.map((item) => (
        <RadioCard
          title={item.title}
          subtitle={item.subtitle}
          imageSrc={item.imageSrc}
          imageMobile={item.imageMobile}
        />
      ))}
    </div>
  );
}

export default RadioMarketing;
