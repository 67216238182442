import clsx from 'clsx';
import React from 'react';

import { Link, useParams } from 'react-router-dom';
import Failure from './Failure';
import Footer from './Footer';
import Header from './Header';
import Success from './Success';

function Thanks() {
  const { result } = useParams();
  const success = result === 'tiene-garantia';
  const color = success ? 'text-[#00488A]' : 'text-white';
  const bgColor = success ? 'bg-black/10' : 'bg-[#BA0606]';

  return (
    <div className={clsx('min-h-screen !text-black', bgColor)}>
      <Header showImage={false} />
      <h2
        className={clsx(
          'text-2xl mt-10 mb-3 font-bold text-center lg:text-[2.5rem] 2xl:mt-8 2xl:mb-4 2xl:text-4xl',
          color,
        )}
      >
        ESTIMADO CLIENTE
      </h2>
      {success && <Success />}
      {!success && <Failure />}
      <div
        className={clsx(
          'flex flex-col mx-5 my-10 gap-y-3 md:gap-y-7 md:text-lg lg:px-44 2xl:px-0 2xl:mx-80 2xl:my-10',
          !success && 'text-white',
        )}
      >
        <p className={clsx('text-sm text-center font-bold')}>
          Consulta a uno de los Distribuidores Mayorista Oficial, para que
          verifique si su equipo es original.
        </p>
        <p className={clsx('text-sm text-center')}>
          Este correo está respaldado por el canal oficial de Motorola Solutions
          en Chile: InterWins y MKS.
        </p>
        <p className={clsx('text-sm text-center')}>
          Si necesitas confirmar otros modelos y N° de serie, te invitamos a
          consultar nuevamente en nuestra landing page:{' '}
          <Link to='/' className={clsx('underline hover:underline')}>
            www.radioscongarantiasam.cl
          </Link>
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Thanks;
