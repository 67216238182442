import React from 'react';

import { Link } from 'react-router-dom';

import RadioAndBoxDesktop from '../images/radio-and-box-desktop.png';

function DesktopDisplay({ title, explanation }) {
  return (
    <div className='hidden lg:flex'>
      <div className='flex items-center justify-center flex-1'>
        <img src={RadioAndBoxDesktop} alt='radio' className='' />
      </div>
      <div className='flex flex-col flex-1 pr-24 gap-y-12'>
        <div className='flex flex-col items-center justify-center gap-y-3'>
          <p className='font-semibold text-3xl pr-44'>{title}</p>
          <p>{explanation}</p>
        </div>
        <Link
          to='/'
          className='bg-[#005EB8] text-white font-semibold rounded-xl py-1.5 px-5 w-72'
        >
          Ver la garantía de tu equipo aquí
        </Link>
      </div>
    </div>
  );
}

function MobileDisplay({ title, explanation }) {
  return (
    <div className='flex flex-col gap-y-5 items-center justify-center lg:hidden'>
      <p className='font-semibold text-2xl text-center'>{title}</p>
      <Link
        to='/'
        className='bg-[#005EB8] text-white font-semibold rounded-xl py-1.5 px-5 w-72'
      >
        Ver la garantía de tu equipo aquí
      </Link>
      <div className='flex items-center justify-center flex-1'>
        <img src={RadioAndBoxDesktop} alt='radio' />
      </div>
      <p className='text-center'>{explanation}</p>
    </div>
  );
}

function RadioGuarantee() {
  const title = '¿Quieres saber si tu equipo tiene garantía Motorola?';
  const explanation =
    'A contar del 21 de septiembre del 2022 , las radios Motorola con garantía de fábrica Motorola Solutions contarán con un sello de garantía. Para comprobar si tu equipo tiene o no garantía, basta con ubicar el Código Modelo y N° de Serie.';

  return (
    <div className='flex items-center w-full text-black gap-x-10 pt-24 pb-10 px-10 lg:px-28'>
      <DesktopDisplay title={title} explanation={explanation} />
      <MobileDisplay title={title} explanation={explanation} />
    </div>
  );
}

export default RadioGuarantee;
