import React, { useState, useMemo } from 'react';

import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

import PopUp from './PopUp';

import NoiseCancellingIconDesktop from '../images/noise-cancelling-icon-desktop.png';
import UpdatesIconDesktop from '../images/updates-logo-desktop.png';
import CompactIconDesktop from '../images/compact-icon-desktop.png';
import BatteryIconDesktop from '../images/battery-icon-desktop.png';
import NoiseSuppressionIconDesktop from '../images/noise-suppression-icon-desktop.png';
import UserExperienceIconDesktop from '../images/user-experience-icon-desktop.png';
import BatterySecondaryIconDesktop from '../images/battery-secondary-icon-desktop.png';
import DesignIconDesktop from '../images/design-icon-desktop.png';

function IconAndDescription({ description, icon }) {
  return (
    <div className='flex flex-col gap-y-3 w-80 items-center justify-center'>
      <img src={icon} alt='icon' />
      <p className='lg:text-sm text-center'>{description}</p>
    </div>
  );
}

function DesktopDisplay({ isGray, mainImg }) {
  return (
    <div className='hidden lg:flex w-full'>
      <div className='flex flex-col items-center justify-center gap-y-10 w-1/3'>
        <div className='flex items-start justify-start w-full'>
          <IconAndDescription
            description={
              isGray
                ? 'Con supresión de ruido SINC+, amplio alcance y perfiles de audio seleccionables; para enviar y recibir mensajes de forma fuerte y clara.'
                : 'Con cancelación TOTAL de ruido avanzada, para una comunicación eficaz incluso en los lugares más ruidosos.'
            }
            icon={
              isGray ? NoiseSuppressionIconDesktop : NoiseCancellingIconDesktop
            }
          />
        </div>
        <div className='flex items-end justify-end w-full'>
          <IconAndDescription
            description={
              isGray
                ? 'Con una experiencia de usuario simplificada, fácil de programar y con soporte continuo para lo que necesites.'
                : 'Con actualizaciones de diseño y opciones de conectividad modernas, dispone de pantalla QVGA de 2,4 pulg. 320 x 240 px, Wi-Fi de 2.4 GHz y 5 GHz y Bluetooth 5.1'
            }
            icon={isGray ? UserExperienceIconDesktop : UpdatesIconDesktop}
          />
        </div>
      </div>
      <div className='flex items-center justify-center w-1/3'>
        <img src={mainImg} alt='radio' className='h-96' />
      </div>
      <div className='flex flex-col items-center justify-center gap-y-10 w-1/3'>
        <div className='flex items-end justify-end w-full'>
          <IconAndDescription
            description={
              isGray
                ? 'Diseño delgado y ligero pero al mismo tiempo resistente, para soportar las condiciones diarias del trabajo cotidiano.'
                : 'Diseño compacto y elegante para una mayor comodidad, pero con la robustez que se necesita para resistir en los entornos más difíciles.'
            }
            icon={isGray ? DesignIconDesktop : CompactIconDesktop}
          />
        </div>
        <div className='flex items-start justify-start w-full'>
          <IconAndDescription
            description={
              isGray
                ? 'Gracias a su batería delgada y de alta intensidad, MOTOTRBO R2 permite trabajar hasta 26 horas, asegurando su uso prolongado durante los turnos de trabajo.'
                : 'Preparada para largas jornadas de trabajo, la batería ligera y compacta del MOTOTRBO R7 permite una autonomía de hasta 28 horas.'
            }
            icon={isGray ? BatterySecondaryIconDesktop : BatteryIconDesktop}
          />
        </div>
      </div>
    </div>
  );
}

function MobileDisplay({ isGray, mainImg }) {
  return (
    <div className='flex lg:hidden w-full'>
      <div className='flex flex-col items-center justify-center gap-y-10 w-full lg:hidden'>
        <img src={mainImg} alt='radio' className='h-96' />
        <IconAndDescription
          description={
            isGray
              ? 'Con supresión de ruido SINC+, amplio alcance y perfiles de audio seleccionables; para enviar y recibir mensajes de forma fuerte y clara.'
              : 'Con cancelación TOTAL de ruido avanzada, para una comunicación eficaz incluso en los lugares más ruidosos.'
          }
          icon={
            isGray ? NoiseSuppressionIconDesktop : NoiseCancellingIconDesktop
          }
        />
        <IconAndDescription
          description={
            isGray
              ? 'Con una experiencia de usuario simplificada, fácil de programar y con soporte continuo para lo que necesites.'
              : 'Con actualizaciones de diseño y opciones de conectividad modernas, dispone de pantalla QVGA de 2,4 pulg. 320 x 240 px, Wi-Fi de 2.4 GHz y 5 GHz y Bluetooth 5.1'
          }
          icon={isGray ? UserExperienceIconDesktop : UpdatesIconDesktop}
        />
        <IconAndDescription
          description={
            isGray
              ? 'Diseño delgado y ligero pero al mismo tiempo resistente, para soportar las condiciones diarias del trabajo cotidiano.'
              : 'Diseño compacto y elegante para una mayor comodidad, pero con la robustez que se necesita para resistir en los entornos más difíciles.'
          }
          icon={isGray ? DesignIconDesktop : CompactIconDesktop}
        />
        <IconAndDescription
          description={
            isGray
              ? 'Gracias a su batería delgada y de alta intensidad, MOTOTRBO R2 permite trabajar hasta 26 horas, asegurando su uso prolongado durante los turnos de trabajo.'
              : 'Preparada para largas jornadas de trabajo, la batería ligera y compacta del MOTOTRBO R7 permite una autonomía de hasta 28 horas.'
          }
          icon={isGray ? BatterySecondaryIconDesktop : BatteryIconDesktop}
        />
      </div>
    </div>
  );
}

function RadioDescription({
  title,
  subtitle,
  buttonText,
  mainImg,
  isGray,
  isBlueButton,
}) {
  const [isR7Open, setIsR7Open] = useState(false);
  const [isR2Open, setIsR2Open] = useState(false);
  const isR7 = useMemo(() => title.includes('R7'), [title]);
  const navigate = useNavigate();

  const handleOpenPopup = () => {
    if (isR7) {
      setIsR7Open(true);
    } else {
      setIsR2Open(true);
    }
  };

  const handleClose = (submit) => {
    if (isR7) {
      setIsR7Open(false);
    } else {
      setIsR2Open(false);
    }
    const path = isR7 ? '/mototrbo/r7' : '/mototrbo/r2';
    if (submit) {
      navigate(path);
    }
  };

  return (
    <div
      className={clsx(
        'flex flex-col text-black py-10 gap-y-7',
        isGray && 'bg-[#FAFAFA]',
      )}
    >
      {isR7 && isR7Open && <PopUp handleClose={handleClose} isR7 />}
      {!isR7 && isR2Open && <PopUp handleClose={handleClose} />}
      <div className='flex flex-col items-center justify-center w-full gap-y-1'>
        <p className='font-semibold text-5xl text-center'>{title}</p>
        <p className='text-2xl text-center'>{subtitle}</p>
        <button
          className={clsx(
            isBlueButton ? 'bg-[#005EB8]' : 'bg-[#00A3E0]',
            'text-white font-semibold rounded-xl py-1 px-5',
          )}
          onClick={handleOpenPopup}
        >
          {buttonText}
        </button>
      </div>
      <div className='flex w-full md:px-32'>
        <DesktopDisplay mainImg={mainImg} isGray={isGray} />
        <MobileDisplay mainImg={mainImg} isGray={isGray} />
      </div>
    </div>
  );
}

export default RadioDescription;
