import React from "react";

function RadioStep({ image, imageXl, title, description }) {
  return (
    <div className="flex gap-y-5 lg:gap-y-3 justify-start items-center flex-col flex-1">
      <img src={image} alt="step" className="lg:hidden" />
      <img
        src={imageXl}
        alt="step desktop"
        className="hidden lg:block w-3/4"
      />
      <h4 className="text-xl font-semibold text-center md:text-3xl lg:text-2xl">
        {title}
      </h4>
      <p className="mx-6 md:text-xl text-justify md:mx-56 lg:mx-10 lg:text-base lg:leading-[1.15rem] 2xl:mx-14">
        {description}
      </p>
    </div>
  );
}

export default RadioStep;
