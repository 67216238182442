import Step1Mobile from "./images/step1-mobile.jpg";
import Step2Mobile from "./images/step2-mobile.jpg";
import Step3Mobile from "./images/step3-mobile.jpg";

import Step1Desktop from "./images/step1-desktop.jpeg";
import Step2Desktop from "./images/step2-desktop.jpeg";
import Step3Desktop from "./images/step3-desktop.jpeg";

export const RADIO_STEPS = [
  {
    title: "Voltear equipo",
    description:
      "Toma tu radio Motorola poniendo el reverso de la cara hacia ti. Toma la tapa trasera, levántala y sácala; En caso que el equipo se encuentre abierto y con batería aparte, pasa al último paso",
    image: Step1Mobile,
    imageXl: Step1Desktop,
  },
  {
    title: "Retirar batería",
    description:
      "Cuando retires la tapa trasera, desliza hacia abajo la bateria de tu radio Motorola. En caso que el equipo se encuentra abierto y con batería aparte, pasa al último paso",
    image: Step2Mobile,
    imageXl: Step2Desktop,
  },
  {
    title: "Ubicar código Model y N° de serie",
    description:
      "El último paso será encontrar el Código Model y el Número de Serie o S/N (ver rectángulo rojo). Deberás escribir los códigos completos en el formulario de más abajo para confirmar si tu equipo Motorola cuenta con garantía",
    image: Step3Mobile,
    imageXl: Step3Desktop,
  },
];
