import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import clsx from "clsx";

import { getGuaranteeResult } from "../api/check";
import Radio from "../images/radio.png";

function FormExplanation() {
  return (
    <div
      className={clsx(
        "w-full",
        "flex flex-col",
        "justify-center items-center",
        "px-5 lg:px-24"
      )}
    >
      <h2 className="w-full text-2xl mb-8 font-bold text-left md:text-center md:text-4xl">
        Confirma tu garantía aquí
      </h2>
      <p className="mb-4 text-left md:w-full md:text-base md:mb-0 lg:text-center">
        Confirma si tu equipo fue adquirido a través del canal oficial de
        Motorola en Chile.
      </p>
      <p className="text-left md:text-base lg:text-center">
        Asegúrate de ingresar correctamente el{" "}
        <span className="font-bold">Código Model</span> y{" "}
        <span className="font-bold">N° de Serie</span> como se indica más
        arriba, para no obtener una respuesta errónea.
      </p>
    </div>
  );
}

function GuaranteeForm({ formRef }) {
  const initValues = { email: "", series: "", model: "" };
  const [email, setEmail] = useState("");
  const [series, setSeries] = useState("");
  const [model, setModel] = useState("");
  const [error, setError] = useState(initValues);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const lettersAndNumbersRegex = /^[A-Za-z0-9]*$/;

  const handleOnClick = async () => {
    setIsLoading(true);
    setError(initValues);

    const validModel = lettersAndNumbersRegex.test(model) && model.length > 5;
    if (!validModel) {
      setError((prevState) => ({
        ...prevState,
        model: "Formato no válido para el modelo ingresado",
      }));
    }

    const validSeries =
      lettersAndNumbersRegex.test(series) && series.length > 5;
    if (!validSeries) {
      setError((prevState) => ({
        ...prevState,
        series: "Formato no válido para la serie ingresada",
      }));
    }

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const validEmail = emailRegex.test(email);
    if (!validEmail) {
      setError((prevState) => ({
        ...prevState,
        email: "Formato no válido para el correo ingresado",
      }));
    }

    if (!validEmail || !validModel || !validSeries) {
      setIsLoading(false);
      return;
    }
    const res = await getGuaranteeResult(series, model, email);
    if (!!res.exists) {
      navigate("/tiene-garantia");
    } else {
      navigate("/no-tiene-garantia");
    }
  };

  const handleOnChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleOnChangeSeries = (e) => {
    setSeries(e.target.value.toUpperCase());
  };

  const handleOnChangeModel = (e) => {
    setModel(e.target.value.toUpperCase());
  };

  return (
    <div>
      <div className="flex mt-32" ref={formRef}>
        {/*<img
          src={Radio}
          alt="radio"
          className="hidden lg:block lg:absolute lg:left-0 lg:h-auto lg:top-[150%] 3xl:top-[145%]"
        />*/}
        <img
          src={Radio}
          alt="radio"
          className="absolute md:hidden h-24 right-0"
        />
        <div className="bg-[#D6D6D6] mx-3 py-10 lg:py-20 lg:mx-56 2xl:mx-80 ">
          <div>
            <FormExplanation />
            <div className="flex flex-col items-center justify-center mx-5 mt-14 md:mt-10 lg:mt-14 lg:mx-32 gap-y-5 ">
              {error.email && (
                <p className="font-semibold text-red-500">{error.email}</p>
              )}
              {error.series && (
                <p className="font-semibold text-red-500">{error.series}</p>
              )}
              {error.model && (
                <p className="font-semibold text-red-500">{error.model}</p>
              )}
              <input
                type="email"
                value={email}
                placeholder="Correo electrónico (obligatorio)"
                className="w-full h-12 px-5 md:text-xs lg:text-sm 2xl:text-base"
                onChange={handleOnChangeEmail}
              />
              <div className="flex flex-col w-full gap-x-8 gap-y-5 lg:flex-row">
                <input
                  type="text"
                  value={series}
                  placeholder="Número de serie (obligatorio)"
                  className="w-full h-12 px-5 md:text-xs lg:w-1/2 lg:text-sm 2xl:text-base"
                  onChange={handleOnChangeSeries}
                />
                <input
                  type="text"
                  value={model}
                  placeholder="Código Model (obligatorio)"
                  className="w-full h-12 px-5 md:text-xs lg:w-1/2 lg:text-sm 2xl:text-base"
                  onChange={handleOnChangeModel}
                />
              </div>
              <button
                className="w-full h-12 font-semibold text-white bg-black hover:bg-black/80 disabled:bg-black/30 disabled:cursor-not-allowed"
                onClick={handleOnClick}
                disabled={isLoading}
              >
                {isLoading ? "Cargando..." : "ENVIAR"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GuaranteeForm;
